import axios from 'axios';

const COLLECTION_DETAILS_API_URL = process.env.REACT_APP_API_URL + '/public/collections';

export const getPublicCollection = async (collectionId, size=25, page=0) => {
  const response = await axios.get(`${COLLECTION_DETAILS_API_URL}/${collectionId}`, {
    params: {
      limit: size,
      offset: size * page
    }
  });
  return response;
};

import Auth from "@aws-amplify/auth";

// todo: add exception handling here for if not logged in?
export const getJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const token = currentSession.idToken.jwtToken

  return token;
};

export default getJWTToken;

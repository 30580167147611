import React, { useState, useEffect, useCallback, useContext } from "react";
import { CheckCircleIcon, PencilIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline'

import { ApplicationContext } from '../../Context/ApplicationContext'

import { getLinks, putLink, postLink as postLinkAPI } from '../../API/links.js'
import { linkTag } from '../../API/linktags.js'
import { addLinkToCollection as addLinkToCollectionAPI } from '../../API/addLinkToCollection.js'
import { getTitleForURL } from '../../API/utils.js'

import Spinner from '../../Components/Spinner'
import Pagination from '../../Components/Pagination'

const Error = ({ error }) => {
  // JSON.stringify(error)
  // w-fit - fit-content - https://stackoverflow.com/questions/66537859/how-to-specify-height-fit-content-with-tailwindcss
  return error ? <div className="py-1 px-4 w-fit border border-orange-500 text-orange-500">{error.message}</div> : null;
};
  
const DataFromAPI = ({
    data,
    update,
    tagLabels,
    tagMap,
    addTag,
    collections,
    addToCollection,
    updatingTagID,
    updatingTagFailureID,
    updatingTagSuccesfulID,
    updatingCollectionID,
    updatingCollectionFailureID,
    updatingCollectionSuccesfulID
  }) => {
  const [hoverID, setHoverID] = useState(null);
  const [editID, setEditID] = useState(null);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [currentTag, setCurrentTag] = useState(null);
  const [currentCollection, setCurrentCollection] = useState(null);

  const TagList =({tags}) => {
    const entries = tags ? tags.map(x =>
      <span className="text-xs text-slate-400" key={`tag-${x}`}>
        {x in tagMap ? tagMap[x] : `tag-${x}`}
      </span>
    ) : [];

    return (
      <div className="space-x-2 flex">
       {entries}
      </div>
    );
  };

  const Tags = () => {
    const entries = tagLabels ? tagLabels.map(x =>
      <option key={x.id} value={x.id} >
        {x.title}
      </option>
    ) : [];

    const addTagToLink = () => {
      // if currentTag is null then fall back to the first tag in the list
      // this happens if the user hasn't made any choice yet in the dropdown
      // and the dropdown defaults to the first one
      let tagToAdd = null;

      if (currentTag != null ) {
        tagToAdd = parseInt(currentTag, 10);
      } else if (tagLabels && tagLabels.length > 0) {
        tagToAdd = parseInt(tagLabels[0].id, 10);
      };

      addTag(editID, tagToAdd);
    };

    return (
      <div className="space-x-2 flex">
        <select {...(currentTag && { value: currentTag })}
            className="text-emerald-400 border border-emerald-400 bg-inherit w-48"
            onChange={(event) => setCurrentTag(event.target.value)}>
          {entries}
        </select>
        { updatingTagID !== editID &&
          <button
            className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400"
            onClick={addTagToLink}
          >
            Add
          </button>
        }
        { updatingTagID === editID && <Spinner /> }
        { updatingTagFailureID === editID && <XCircleIcon className="h-5 w-5 text-red-600"/> }
        {
          // updatingTagSuccesfulID === editID && <CheckCircleIcon className="h-5 w-5 text-emerald-400"/>
        }
      </div>
    );
  }

  const Collections = () => {
    const entries = collections ? collections.map(x =>
      <option key={x.id} value={x.id} >
        {x.title}
      </option>
    ) : [];

    const addLinkToCollection = () => {
      // if currentCollection is null then fall back to the first tag in the list
      // this happens if the user hasn't made any choice yet in the dropdown
      // and the dropdown defaults to the first one
      let collectionID = null;

      if (currentCollection != null ) {
        collectionID = parseInt(currentCollection, 10);
      } else if (collections && collections.length > 0) {
        collectionID = parseInt(collections[0].id, 10);
      };

      addToCollection(editID, collectionID);
    };

    return (
      <div className="space-x-2 flex">
        <select {...(currentCollection && { value: currentCollection })}
            className="text-emerald-400 border border-emerald-400 bg-inherit w-48"
            onChange={(event) => setCurrentCollection(event.target.value)}>
          {entries}
        </select>

        { updatingCollectionID !== editID &&
          <button
            className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400"
            onClick={addLinkToCollection}
          >
            Add
          </button>
        }
        { updatingCollectionID === editID && <Spinner /> }
        { updatingCollectionFailureID === editID && <XCircleIcon className="h-5 w-5 text-red-600"/> }
        {
          // updatingCollectionSuccesfulID === editID && <CheckCircleIcon className="h-5 w-5 text-emerald-400"/>
        }
      </div>
    );
  }

  // if( !data ) {
  //   return <div>No Data</div>
  // }
  // hover:border hover:border-1 hover:border-slate-500
  // https://reactjs.org/docs/events.html#mouse-events
  const entries = data ? data.map(x =>
    <div key={x.id}
          className="flex flex-row space-x-2 hover:bg-cyan-800 cursor-pointer"
          onMouseEnter={() => setHoverID(x.id)}
          onMouseLeave={() => setHoverID(null)}
      >

      { x.id !== editID &&
        <div className="space-x-2 flex items-baseline">
          <a className="hover:underline hover:text-emerald-400" href={x.url}>
            {x.title ? x.title : x.url}
          </a>
          <TagList tags={x.tags} />
        </div>
      }

      { x.id === editID && (
        <div className="w-4/5 flex flex-col space-y-2 my-4">
            <span className="text-emerald-400">Title:</span>
            <input
              className="text-sm p-1 ml-3 text-zinc-900 w-2/3"
              name="title"
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
            <span className="text-emerald-400">Url:</span>
            <input
              className="text-sm p-1 ml-3 text-zinc-900 w-2/3"
              name="url"
              type="text"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
            />
            <span className="text-emerald-400">Tags:</span>
            <TagList tags={x.tags} />
            <Tags />
            <span className="text-emerald-400">Collections:</span>
            <Collections />
        </div>
      )}

      {
        /*x.id === hoverID &&
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 fill-emerald-400 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
          </svg>*/

        x.id === hoverID && editID !== x.id && <PencilIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => { setEditID(x.id); setTitle(x.title); setUrl(x.url) }}/>
        //x.id === hoverID && editID === null && <ChevronDownIcon className="h-5 w-5 text-emerald-400" onClick={() => { setEditID(x.id); setTitle(x.title); setUrl(x.url) }}/>
      }

      {
        x.id === editID && <CheckCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => {update(editID, title, url); setEditID(null)}}/>
      }

      {
        x.id === editID && <XCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => setEditID(null)}/>
      }

    </div>
  ) : [];

  return (
    <div className="space-y-2">
      {entries}
    </div>
  )
};

export const Links = ({params}) => {
  let tagId = null;
  if (params && 'tagId' in params) {
    ({tagId} = params);
  }

  const context = useContext(ApplicationContext);

  // todo: why is this run 6 times during page load?
  //console.log('links');

  const DEFAULT_TAG_TITLE = 'Inbox';
  const NO_TAG_TITLE = 'Links without tags';
  const [tagTitle, setTagTitle] = useState(DEFAULT_TAG_TITLE);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatingTagID, setUpdatingTagID] = useState(null);
  const [updatingTagFailureID, setUpdatingTagFailureID] = useState(null);
  const [updatingTagSuccesfulID, setUpdatingTagSuccesfulID] = useState(null);
  const [updatingCollectionID, setUpdatingCollectionID] = useState(false);
  const [updatingCollectionSuccesfulID, setUpdatingCollectionSuccesfulID] = useState(null);
  const [updatingCollectionFailureID, setUpdatingCollectionFailureID] = useState(null);
  const [dataFromAPI, setDataFromAPI] = useState(null);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const size = 25;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  // todo: why am I using useCallback here?
  // is it because i provide them as functions to onKeyDown, onClick etc?
  // they will get re-rendered whenever this changes, right?
  // or is it to make sure they get updated whenever any dependency changes?
  const refreshList = useCallback( async () => {
    setLoading(true);
    setError(null);
    try {
      // todo: why do i have to use await here? is async actually returning a promise?
      const response = await getLinks(size, page, search, null, tagId);
      setDataFromAPI(response.data.items);
      setTotal(response.data.total);

    } catch (e) {
      console.error(e);
      setError({message:'Error while trying to refresh list'});
    };
    setLoading(false);    
  },[setDataFromAPI, setError, setLoading, page, search, tagId])

  useEffect(() => {
    refreshList();
  },[page, refreshList]);

  const updateLink = useCallback(
    async (id,title,url) => {
      setLoading(true);
      setError(null);

      try {
        // todo: why do i have to use await here? is async actually returning a promise?
        const response = await putLink(id, url, title);

        setUrl("");
        setTitle("");
        refreshList();
      } catch (e) {
        console.error(e);
        setError({message:'Error while trying to update link'});
      };
      setLoading(false);

    },
    [refreshList]
  );
    
  const postLink = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        let linkTitle = title;
        if (linkTitle == null || linkTitle.length == 0) {
          const response = await getTitleForURL(url);
          linkTitle = response.data.title;
        };
        // todo: why do i have to use await here? is async actually returning a promise?
        const response = await postLinkAPI(url, linkTitle);
        setUrl("");
        setTitle("");
        refreshList();
      } catch (e) {
        console.log(e);
        if (e.response.status === 409) {
          setError({message:'Link already exists'});
          //console.log(e.response);
        } else {
          setError({message:'Error while trying to post link'});
        }
      };
      setLoading(false);
  
    },
    [url, title, refreshList]
  );

  const updateTagTitle = (tagId, tagMap) => {
    if(tagId in tagMap) {
      setTagTitle(tagMap[tagId]);
      // todo: is the == 0 intentional here?
    } else if (tagId == 0) {
      setTagTitle(NO_TAG_TITLE);
    } else {
      setTagTitle(tagId ? `tag-${tagId}` : DEFAULT_TAG_TITLE);
    }
  }

  useEffect(() => {
    updateTagTitle(tagId, context.tags.map);
  },[tagId, context]);

  const addTagToLink = useCallback(
    async (linkID, tagID) => {
      // console.log(`add tag ${tagID} to link ${linkID}`)
      setUpdatingTagID(linkID);
      setUpdatingTagSuccesfulID(null);
      setUpdatingTagFailureID(null);
      setError(null);

      try {
        const response = await linkTag(linkID, tagID);
        refreshList();
        setUpdatingTagSuccesfulID(linkID);
        setUpdatingTagFailureID(null);
      } catch (e) {
        console.error(e);
        setError({message:'Error while trying to add tag to link'});
        setUpdatingTagFailureID(linkID);
        setUpdatingTagSuccesfulID(null);
      };

      setUpdatingTagID(null);
    },
    [refreshList]
  );

  const addLinkToCollection = useCallback(
    async (linkID, collectionID) => {
      // console.log(`add tag ${tagID} to link ${linkID}`)
      setError(null);
      setUpdatingCollectionID(linkID);
      setUpdatingCollectionSuccesfulID(null);
      setUpdatingCollectionFailureID(null);

      try {
        const response = await addLinkToCollectionAPI(linkID, collectionID);
        setUpdatingCollectionSuccesfulID(linkID);
        setUpdatingCollectionFailureID(null);
      } catch (e) {
        console.error(e);
        setError({message:'Error while trying to add link to collection'});
        setUpdatingCollectionFailureID(linkID);
        setUpdatingCollectionSuccesfulID(null);
      };

      setUpdatingCollectionID(null);
    },
    []
  );

  return (
    <div className="space-y-4">
      <h1 className="text-6xl">{tagTitle}</h1>
      <div className="space-x-2 flex justify-center items-center">
          <span className="text-emerald-400">Url:</span>
          <input
            className="appearance-none bg-slate-700 text-sm p-1 ml-2 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
            name="url"
            type="text"
            value={url}
            onChange={(event) => setUrl(event.target.value)}
            onKeyDown={(event) => event.code === 'Enter' && postLink(event)}
          />

          <span className="text-emerald-400">Title:</span>
          <input
            className="appearance-none bg-slate-700 text-sm p-1 ml-2 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
            name="title"
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            onKeyDown={(event) => event.code === 'Enter' && postLink(event)}
          />

          <button className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400" onClick={postLink}>Add</button>
      </div>

      <Error error={error} />

      <div className="w-4/5 space-x-2 flex items-center">
          <input
            className="appearance-none bg-slate-700 text-sm p-1 ml-2 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
            name="search"
            type="text"
            value={searchTerm}
            onKeyPress={(event) => event.key === "Enter" && setSearch(searchTerm.trim())}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <XCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => {setSearchTerm(""); setSearch("")}}/>
          <button className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400" onClick={() => setSearch(searchTerm.trim())}>Search</button>
          { loading && <Spinner />}
      </div>

      <DataFromAPI
        data={dataFromAPI}
        update={updateLink}
        tagLabels={context.tags.items}
        tagMap={context.tags.map}
        addTag={(linkID, tagID) => addTagToLink(linkID, tagID)}
        collections={context.collections.items}
        addToCollection={(linkID, collectionID) => addLinkToCollection(linkID, collectionID)}
        updatingTagID={updatingTagID}
        updatingTagFailureID={updatingTagFailureID}
        updatingTagSuccesfulID={updatingTagSuccesfulID}
        updatingCollectionID={updatingCollectionID}
        updatingCollectionFailureID={updatingCollectionFailureID}
        updatingCollectionSuccesfulID={updatingCollectionSuccesfulID}
      />

      <div className="space-x-2 flex items-center">
        <Pagination total={total} size={size} page={page} setPage={setPage} />
        { !loading && <RefreshIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => refreshList()}/> }
        { loading && <Spinner />}
      </div>
    </div>
  );
}

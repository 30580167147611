import React, { useState, useEffect, useCallback, useContext } from "react";
import { CheckCircleIcon, PencilIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline'
import { Link } from 'wouter'

import { ApplicationContext } from '../../Context/ApplicationContext'

import { putTag, postTag as postTagAPI } from '../../API/tags.js'

import Spinner from '../../Components/Spinner'
import Pagination from '../../Components/Pagination'

const Error = ({ error }) => {
  return error ? <div>{JSON.stringify(error)}</div> : null;
};
  
const DataFromAPI = ({data, update}) => {
  const [hoverID, setHoverID] = useState(null);
  const [editID, setEditID] = useState(null);
  const [title, setTitle] = useState("");

  // https://reactjs.org/docs/events.html#mouse-events
  const entries = data ? data.map(x =>
    <div key={x.id}
          className="flex flex-row space-x-2 hover:bg-cyan-800 cursor-pointer"
          onMouseEnter={() => setHoverID(x.id)}
          onMouseLeave={() => setHoverID(null)}
      >

      { x.id !== editID &&
        <Link className="hover:underline hover:text-emerald-400" href={`/links/tag/${x.id}`}>
          {x.title}
        </Link>
      }

      { x.id === editID && (
        <div className="w-4/5 flex flex-col space-y-1 my-4">
            <span className="text-emerald-400">Title:</span>
            <input
              className="text-sm p-1 ml-3 text-zinc-900 w-2/3"
              name="title"
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              onKeyDown={(event) => event.code === 'Enter' && update(editID, title) && setEditID(null)}
            />
        </div>
      )}

      {
        x.id === hoverID && editID !== x.id && <PencilIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => { setEditID(x.id); setTitle(x.title); }}/>
      }

      {
        x.id === editID && <CheckCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => {update(editID, title); setEditID(null)}}/>
      }

      {
        x.id === editID && <XCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => setEditID(null)}/>
      }

    </div>
  ) : [];

  return (
    <div className="space-y-2">
      <Link className="hover:underline hover:text-emerald-400 text-emerald-400 italic" href={`/links/tag/0`}>
        Links without tags
      </Link>
      {entries}
    </div>
  )
};

export const Tags = () => {
  const context = useContext(ApplicationContext);
  //console.log(context);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const size = 25;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    if(context.tagsAvailable) {
      const tags = search.length > 0 ? context.tags.items.filter(t => t.title.toLowerCase().includes(search.toLowerCase())) : context.tags.items;
      setFilteredTags(tags.slice(page*size, (page+1)*size));
      setTotal(tags.length);
    }
  },[context, search, page, size]);

  const updateTag = useCallback(
    async (id,title) => {
      setLoading(true);
      setError(null);

      try {
        // todo: why do i have to use await here? is async actually returning a promise?
        const response = await putTag(id, title);

        setTitle("");
        context.refreshTags();
      } catch (e) {
        console.error(e);
        setError(e);
      };
      setLoading(false);

    },
    [context]
  );

  const postTag = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        // todo: why do i have to use await here? is async actually returning a promise?
        const response = await postTagAPI(title);
        setTitle("");
        context.refreshTags();
      } catch (e) {
        console.error(e);
        setError(e);
      };
      setLoading(false);
  
    },
    [title, context]
  );
  
  // todo: separate loading variables for adding and updating and replace button while in progress
  const showLoading = loading || context.tagsLoading;
  return (
    <div className="space-y-4">
      <h1 className="text-6xl">Tags</h1>
      <div className="w-4/5 space-x-2 flex items-center">
          <input
            className="appearance-none bg-slate-700 text-sm p-1 ml-2 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
            name="search"
            type="text"
            value={searchTerm}
            onKeyPress={(event) => event.key === "Enter" && setSearch(searchTerm.trim())}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <XCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => {setSearchTerm(""); setSearch("")}}/>
          <button className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400" onClick={() => setSearch(searchTerm.trim())}>Search</button>
          { loading && <Spinner />}
      </div>

      <DataFromAPI data={filteredTags} update={updateTag}/>

      <div className="w-4/5 space-x-2 flex items-center">
          <input
            className="appearance-none bg-slate-700 text-sm p-1 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
            name="title"
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            onKeyDown={(event) => event.code === 'Enter' && postTag(event)}
          />

          <button className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400" onClick={postTag}>Add</button>
      </div>

      <div className="space-x-2 flex items-center">
        <Pagination total={total} size={size} page={page} setPage={setPage} />
        { !showLoading && <RefreshIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => context.refreshTags()}/> }
        { showLoading && <Spinner />}
      </div>

      <Error error={error} />
    </div>
  );
}

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

export const Pagination = ({total, size, page, setPage}) => {
    const numberOfPages = Math.ceil(total / size);
    const maxShownPages = Math.min(numberOfPages,20);
    const shownPages = Math.min(numberOfPages, maxShownPages);
    const previousPage = Math.max(page-1, 0);
    const nextPage = Math.min(page+1, numberOfPages-1);
  
    const renderPaginationEntry = (page, currentPage) => page === currentPage ? 
      <div className="text-emerald-400 border-emerald-400 border-b" key={`page_${page}`}>{page+1}</div>
      :
      <div className="cursor-pointer hover:text-emerald-400" key={`page_${page}`} onClick={() => setPage(page)}>{page+1}</div>;
  
    let offset = 0;
    if(page > shownPages/2) {
      const maxOffset = numberOfPages - shownPages;
      offset = Math.min(page - maxShownPages /2, maxOffset);
    }
  
    const pages = [];
  
    if(offset > 0) {
      pages.push(<div key="dotsleft">...</div>);
    }
  
    for(let i = 0; i<shownPages; ++i) {
      pages.push(renderPaginationEntry(i+offset, page));
    }
  
    if(offset + shownPages < numberOfPages) {
      pages.push(<div key="dotsright">...</div>);
    }
  
    return  <div className="flex flex-row space-x-1 items-center">
              {
                page > 0 ?
                  <ChevronLeftIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => setPage(previousPage)}/>
                :
                  <ChevronLeftIcon className="cursor-pointer h-5 w-5 text-slate-400"/>
              }
              <div className="text-lg flex flex-row space-x-1" >{pages}</div>
              {
                page < numberOfPages - 1 ?
                  <ChevronRightIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => setPage(nextPage)}/>
                :
                  <ChevronRightIcon className="cursor-pointer h-5 w-5 text-slate-400"/>
              }
              <div className="text-xs text-slate-400">({total})</div>
            </div>
  };

  export default Pagination;

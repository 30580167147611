import React, { useState, useEffect, useCallback, useContext } from "react";
import { RefreshIcon } from '@heroicons/react/outline'

import { ApplicationContext } from '../../Context/ApplicationContext'

import { getPublicCollection } from '../../API/publicCollection.js'

import Spinner from '../../Components/Spinner'
import Pagination from '../../Components/Pagination'

const Error = ({ error }) => {
  return error ? <div>{JSON.stringify(error)}</div> : null;
};
  
const DataFromAPI = ({data}) => {
  const entries = data ? data.map(x =>
    <div key={x.id}
          className="flex flex-row space-x-2 hover:bg-cyan-800 cursor-pointer"
      >
      <div className="space-x-2 flex items-baseline">
        <a className="hover:underline hover:text-emerald-400" href={x.url}>
          {x.title ? x.title : x.url}
        </a>
      </div>
    </div>
  ) : [];

  return (
    <div className="space-y-2">
      {entries}
    </div>
  )
};

export const PublicCollectionDetails = ({params}) => {
  const {collectionId} = params;
  const context = useContext(ApplicationContext);

  const [collectionDetails, setCollectionDetails] = useState({
    title: "",
    description: ""
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFromAPI, setDataFromAPI] = useState(null);
  const size = 25;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const refreshList = useCallback( async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getPublicCollection(collectionId, size, page);
      setDataFromAPI(response.data.items);
      setTotal(response.data.total);
      setCollectionDetails({
        "title": response.data.title,
        "description": response.data.description
      })

    } catch (e) {
      console.error(e);
      setError(e);
    };
    setLoading(false);    
  },[setDataFromAPI,setError,setLoading,page, collectionId])

  useEffect(() => {
    refreshList();
  },[page, refreshList]);

  return (
    <div className="space-y-4">
      <h1 className="text-6xl">{collectionDetails.title}</h1>
      <h2 className="text-xl">{collectionDetails.description}</h2>
      <div className="pt-12 w-4/5 space-x-2 flex items-center">
        { loading && <Spinner />}
      </div>      

      <DataFromAPI data={dataFromAPI} />

      <div className="space-x-2 flex items-center">
        <Pagination total={total} size={size} page={page} setPage={setPage} />
        { !loading && <RefreshIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => refreshList()}/> }
        { loading && <Spinner />}
      </div>

      <Error error={error} />      
    </div>
  );
}

import React, { useState, useCallback, useEffect } from "react";
import { getCollections } from '../API/collections.js'
import { getTags } from '../API/tags.js'

const DEFAULT_TAG_STATE = {
    items: [],
    map: {},
};

const DEFAULT_COLLECTION_STATE = {
  items: []
};

const DEFAULT_LINKS_STATE = {
  links: [],
  total: 0,
  search: null,
  page: 0,
  tagID: null,
}

const DEFAULT_CONTEXT = {
  tags: DEFAULT_TAG_STATE,
  tagsLoading: false,
  tagsAvailable: false,
  collections: DEFAULT_COLLECTION_STATE,
  collectionsLoading: false,
  collectionsAvailable: false,
  links: DEFAULT_LINKS_STATE,
  linksLoading: false,
  linksAvailable: false,
}

// todo: show loading screen if tags or collection not loaded
// refresh by setTimeout every 10s to trigger refresh?

export const ApplicationContext = React.createContext(DEFAULT_CONTEXT);

export const ApplicationContextProvider = props => {
  const [tags, setTags] = useState(DEFAULT_TAG_STATE);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagsAvailable, setTagsAvailable] = useState(false);
  const [collections, setCollections] = useState(DEFAULT_COLLECTION_STATE);
  const [collectionsLoading, setCollectionsLoading] = useState(false);
  const [collectionsAvailable, setCollectionsAvailable] = useState(false);

  // todo: why am I using useCallback here?
  // is it because i provide them as functions to onKeyDown, onClick etc?
  // they will get re-rendered whenever this changes, right?
  // or is it to make sure they get updated whenever any dependency changes?
  const refreshTags = useCallback(async () => {
    console.log("refresh tags");
    if (tagsLoading) {
      console.log("tags already loading - skipping this one");
      return;
    };

    setTagsLoading(true);
    try {
      // todo: this only supports 200 tags
      const response = await getTags(200, 0, "");
      // https://stackoverflow.com/questions/42974735/create-object-from-array
      const newTagMap = response.data.items.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.title }), {});
      setTags({
          items: response.data.items,
          map: newTagMap
      });
      setTagsAvailable(true);
    } catch (e) {
      // todo: should we set it to false here?
      setTagsAvailable(false);
      console.error(e);
    };

    setTagsLoading(false);
  },[tagsLoading]);

  const refreshCollections = useCallback( async () => {
    console.log("refresh collections");
    if (collectionsLoading) {
      console.log("collections already loading - skipping this one");
      return;
    };

    setCollectionsLoading(true);
    try {
      const response = await getCollections(200, 0, "");
      // collections map is an object where each entry points to the index of the item with that id in the items array
      const newCollectionsMap = response.data.items.reduce((acc, cur, currentIndex) => ({ ...acc, [cur.id]: currentIndex }), {});

      setCollections({
        items: response.data.items,
        map: newCollectionsMap
      });
      setCollectionsAvailable(true);
    } catch (e) {
      console.error(e);
      // todo: should we set it to false here?
      setCollectionsAvailable(false);
    };

    setCollectionsLoading(false);
  },[collectionsLoading])

  // load tags on startup
  useEffect(() => {
    if(!tagsAvailable && !tagsLoading) {
      refreshTags();
    };
  },[tagsAvailable, tagsLoading, refreshTags]);

  // load collections on startup
  useEffect(() => {
    if(!collectionsAvailable && !collectionsLoading) {
      refreshCollections();
    };
  },[collectionsAvailable, collectionsLoading, refreshCollections]);

  return (
    <ApplicationContext.Provider
      value={{
        tags,
        tagsLoading,
        tagsAvailable,
        collections,
        collectionsLoading,
        collectionsAvailable,
        refreshLinks: () => {console.log("refresh links")},
        refreshTags,
        refreshCollections
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};

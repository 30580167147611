import { getJWTToken } from './getJWTToken'
import axios from 'axios';

const TAGS_API_URL = process.env.REACT_APP_API_URL + '/tags';

export const getTags = async (size=25, page=0, search=null) => {
  const token = await getJWTToken();
  const response = await axios.get(TAGS_API_URL, {
    headers: {
      Authorization: "Bearer " + token
    },
    params: {
      limit: size,
      offset: size * page,
      ...(search && search.length > 0 && { search })
    }
  });

  return response;
};

export const putTag = async (id, title) => {
  const token = await getJWTToken();
  const response = await axios.put(TAGS_API_URL,
  {
    id,
    title
  },
  {
    headers: {
      Authorization: "Bearer " + token
    }
  });

  return response;
};

export const postTag = async (title) => {
  const token = await getJWTToken();
  const response = await axios.post(TAGS_API_URL,
  {
    title
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}

import Auth from "@aws-amplify/auth";

export const SignoutButton = () => (
  <button
    type="button"
    className="text-emerald-400 text-sm"
    onClick={async () => {
      try {
        await Auth.signOut();
      } catch (e) {
        console.error(e);
      }
    }}
  >
    Signout
</button>  
);

export default SignoutButton;

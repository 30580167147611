import { getJWTToken } from './getJWTToken'
import axios from 'axios';

export const getTitleForURL = async url => {
  const token = await getJWTToken();
  const response = await axios.post(process.env.REACT_APP_API_URL+'/utils/title',
  {
    url,
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}

import { Link, useRoute } from 'wouter'

export const NavigationLink = (props) => {
  const [match] = useRoute(props.href);

  return (
    <Link {...props}>
      <span className={match ? "cursor-pointer underline text-emerald-400" : "cursor-pointer hover:text-emerald-400"}>{props.children}</span>
    </Link>
  );

};

export default NavigationLink;

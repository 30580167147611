import { getJWTToken } from './getJWTToken'
import axios from 'axios';

const LINKTAGS_API_URL = process.env.REACT_APP_API_URL + '/linktags';

export const linkTag = async (linkID, tagID) => {
  const token = await getJWTToken();
  const response = await axios.post(LINKTAGS_API_URL,
  {
    itemID: linkID,
    tagID
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}

import { getJWTToken } from './getJWTToken'
import axios from 'axios';

const COLLECTION_ITEMS_API_URL = process.env.REACT_APP_API_URL + '/collectionitems';

export const addLinkToCollection = async (linkID, collectionID) => {
  const token = await getJWTToken();
  const response = await axios.post(COLLECTION_ITEMS_API_URL,
  {
    itemID: linkID,
    collectionID
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}

import { getJWTToken } from './getJWTToken'
import axios from 'axios';

const COLLECTIONS_API_URL = process.env.REACT_APP_API_URL + '/collections';
const COLLECTION_DETAILS_API_URL = process.env.REACT_APP_API_URL + '/collection';

export const getCollections = async (size=25, page=0, search=null) => {
  const token = await getJWTToken();
  const response = await axios.get(COLLECTIONS_API_URL, {
    headers: {
      Authorization: "Bearer " + token
    },
    params: {
      limit: size,
      offset: size * page,
      ...(search && search.length > 0 && { search })
    }
  });

  return response;
};

export const getCollection = async (collectionId) => {
  const token = await getJWTToken();
  const response = await axios.get(`${COLLECTION_DETAILS_API_URL}/${collectionId}`, {
    headers: {
      Authorization: "Bearer " + token
    }
  });

  return response;
};

export const putCollection = async (id, title, description = "", isPublic = false) => {
  const token = await getJWTToken();
  const response = await axios.put(COLLECTIONS_API_URL,
  {
    id,
    title,
    description,
    public: isPublic
  },
  {
    headers: {
      Authorization: "Bearer " + token
    }
  });

  return response;
};

export const postCollection = async (title, description = "", isPublic = false) => {
  const token = await getJWTToken();
  const response = await axios.post(COLLECTIONS_API_URL,
  {
    title,
    description,
    public: isPublic
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}

import Auth from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types";

export const SigninButton = () => (
  <button
    type="button"
    className="text-emerald-400 text-sm"
    onClick={() =>
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito
      })
    }
  >
    Federated sign in
  </button>
);

export default SigninButton;

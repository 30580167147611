import React, { useState, useEffect, useCallback, useContext } from "react";
import { RefreshIcon, XCircleIcon } from '@heroicons/react/outline'

import { ApplicationContext } from '../../Context/ApplicationContext'

import { getLinks } from '../../API/links.js'

import Spinner from '../../Components/Spinner'
import Pagination from '../../Components/Pagination'

const Error = ({ error }) => {
  return error ? <div>{JSON.stringify(error)}</div> : null;
};
  
const DataFromAPI = ({data}) => {
  const entries = data ? data.map(x =>
    <div key={x.id}
          className="flex flex-row space-x-2 hover:bg-cyan-800 cursor-pointer"
      >
      <div className="space-x-2 flex items-baseline">
        <a className="hover:underline hover:text-emerald-400" href={x.url}>
          {x.title ? x.title : x.url}
        </a>
      </div>
    </div>
  ) : [];

  return (
    <div className="space-y-2">
      {entries}
    </div>
  )
};

export const CollectionDetails = ({params}) => {
  const {collectionId} = params;
  const context = useContext(ApplicationContext);

  const collectionDetails = context.collectionsAvailable && collectionId in context.collections.map ?
  context.collections.items[context.collections.map[collectionId]] :
  {
    title: "",
    description: "",
    public: false
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFromAPI, setDataFromAPI] = useState(null);
  const size = 25;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  // todo: why am I using useCallback here?
  // is it because i provide them as functions to onKeyDown, onClick etc?
  // they will get re-rendered whenever this changes, right?
  // or is it to make sure they get updated whenever any dependency changes?
  const refreshList = useCallback( async () => {
    setLoading(true);
    setError(null);
    try {
      // todo: why do i have to use await here? is async actually returning a promise?
      const response = await getLinks(size, page, search, collectionId);
      setDataFromAPI(response.data.items);
      setTotal(response.data.total);

    } catch (e) {
      console.error(e);
      setError(e);
    };
    setLoading(false);    
  },[setDataFromAPI,setError,setLoading,page, search, collectionId])

  useEffect(() => {
    refreshList();
  },[page, refreshList]);

  return (
    <div className="space-y-4">
      { context.collectionsLoading && <Spinner />}
      <h1 className="text-6xl">{collectionDetails.title}</h1>
      { collectionDetails.public && <a className="text-emerald-400 text-sm" href={`/public/collections/${collectionId}`}>Public Link</a>}
      <h2 className="text-xl">{collectionDetails.description}</h2>
      <div className="pt-12 w-4/5 space-x-2 flex items-center">
            <input
              className="appearance-none bg-slate-700 text-sm p-1 ml-2 text-emerald-400 w-1/3 border border-emerald-400 rounded focus:outline-none focus:border-purple-500"
              name="search"
              type="text"
              value={searchTerm}
              onKeyPress={(event) => event.key === "Enter" && setSearch(searchTerm.trim())}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <XCircleIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => {setSearchTerm(""); setSearch("")}}/>
            <button className="h-6 px-2 text-sm border border-emerald-400 rounded-md text-emerald-400" onClick={() => setSearch(searchTerm.trim())}>Search</button>
            { loading && <Spinner />}
        </div>      

      <DataFromAPI data={dataFromAPI} />

      <div className="space-x-2 flex items-center">
          <Pagination total={total} size={size} page={page} setPage={setPage} />
          { !loading && <RefreshIcon className="cursor-pointer h-5 w-5 text-emerald-400" onClick={() => refreshList()}/> }
          { loading && <Spinner />}
      </div>

      <Error error={error} />      
    </div>
  );
}

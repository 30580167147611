import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from 'wouter'

import { ApplicationContextProvider } from './Context/ApplicationContext'

import { Links } from './Pages/Links/Links.jsx'
import { Collections } from './Pages/Collections/Collections.jsx'
import { CollectionDetails } from './Pages/Collections/CollectionDetails.jsx'
import { Tags } from './Pages/Tags/Tags.jsx'
import { PublicCollectionDetails } from './Pages/PublicCollections/PublicCollectionDetails.jsx'

import NavigationLink from './Components/NavigationLink.jsx'
import SigninButton from './Components/SigninButton.jsx'
import SignoutButton from './Components/SignoutButton.jsx'

import { getJWTToken } from './API/getJWTToken.js'

function App() {
  const [jwtToken, setJwtToken] = useState(null);

  // this is done to be able to read jwtToken (i.e if we are logged in)
  // for showing the signin/signout button as getJWTToken is asynchronous
  // https://devtrium.com/posts/async-functions-useeffect
  useEffect(() => {
    const doStuff = async () => {
      try {
        const currentToken = await getJWTToken()
        setJwtToken(currentToken);
      } catch (e) {
        console.error(e);
        setJwtToken(null);
      };
    }

    doStuff();
  },[]);

  // todo: figure out appropriate classnames for out div
  return (
    <ApplicationContextProvider>
      <div className="space-y-4 p-8">
        <div className="flex justify-between">
          <div className="space-x-2">
            <NavigationLink href="/links">Links</NavigationLink>
            <NavigationLink href="/tags">Tags</NavigationLink>
            <NavigationLink href="/collections">Collections</NavigationLink>
          </div>
          <div>
            {jwtToken == null? <SigninButton /> : <SignoutButton/> }
          </div>
        </div>

        <Switch>
          <Route path="/"><Redirect to="/links" /></Route>
          <Route path="/links"><Links /></Route>
          <Route path="/links/tag/:tagId" component={Links}/>
          <Route path="/tags"><Tags /></Route>
          <Route path="/collections/:collectionId" component={CollectionDetails} />
          <Route path="/collections"><Collections /></Route>
          <Route path="/public/collections/:collectionId" component={PublicCollectionDetails} />
        </Switch>

      </div>
    </ApplicationContextProvider>
  );
};

export default App;

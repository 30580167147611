import { getJWTToken } from './getJWTToken'
import axios from 'axios';

export const getLinks = async (size=25, page=0, search=null, collection=null, tag=null) => {
  const token = await getJWTToken();
  const response = await axios.get(process.env.REACT_APP_API_URL + '/links', {
    headers: {
      Authorization: "Bearer " + token
    },
    params: {
      limit: size,
      offset: size * page,
      ...(search && search.length > 0 && { search }),
      ...(collection && { collection }),
      ...(tag && { tag }),
    }
  });

  return response;
};

export const putLink = async (id, url, title) => {
  const token = await getJWTToken();
  const response = await axios.put(process.env.REACT_APP_API_URL+'/links',
  {
    id,
    url,
    title
  },
  {
    headers: {
      Authorization: "Bearer " + token
    }
  });

  return response;
};

export const postLink = async (url, title) => {
  const token = await getJWTToken();
  const response = await axios.post(process.env.REACT_APP_API_URL+'/links',
  {
    avoid_duplicate_links: true,
    url,
    title
  },
  {
    headers: {
       Authorization: "Bearer " + token
    }
  });

  return response;
}